import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menü | Melody Haven Karaoke Bar Menüsü
			</title>
			<meta name={"description"} content={"Her bir öğenin, şarkı ve kutlama gecenizi tamamlamak için hazırlanmış bir lezzet harmonisi olduğu menümüze dalın.\n"} />
			<meta property={"og:title"} content={"Menü | Melody Haven Karaoke Bar Menüsü"} />
			<meta property={"og:description"} content={"Her bir öğenin, şarkı ve kutlama gecenizi tamamlamak için hazırlanmış bir lezzet harmonisi olduğu menümüze dalın.\n"} />
			<meta property={"og:image"} content={"https://combistale.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://combistale.com/img/141511009.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://combistale.com/img/141511009.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://combistale.com/img/141511009.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://combistale.com/img/141511009.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://combistale.com/img/141511009.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://combistale.com/img/141511009.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://combistale.com/img/141511009.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 120px 0" background="--color-darkL2" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 50px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					sm-padding="0px 0 0px 0px"
					sm-margin="0px 0px 15px 0px"
				>
					<Text margin="0px 0px 30px 0px" color="--light" font="normal 900 48px/1.2 --fontFamily-sans">
						Menü
					</Text>
					<Text margin="0px 0px 40px 0px" color="--lightD2" font="300 18px/140% --fontFamily-sansHelvetica">
						Her bir öğenin, şarkı ve kutlama gecenizi tamamlamak için hazırlanmış bir lezzet harmonisi olduğu menümüze dalın.
					</Text>
					<Button
						background="--color-primary"
						color="--dark"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="15px 44px 15px 44px"
						font="normal 700 14px/1.5 --fontFamily-sansHelvetica"
						text-transform="uppercase"
					>
						TAM MENÜYÜ GÖRÜNTÜLE &gt;&gt;
					</Button>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://combistale.com/img/a1.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Vokal Isınma:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Zencefil ve burbonun bu ateşli karışımıyla şarkı söyleme ruhunuzu ateşleyin, üzerine bir tutam limon ekleyin.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://combistale.com/img/a2.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Melodik Mojito:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Nane, misket limonu ve romun bu klasik karışımına Melody Haven dokunuşuyla paletinizi tazeleyin.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://combistale.com/img/a3.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Yıldızlarla Dolu Sürgüler:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Üçlü mini burger, setler arasında paylaşmak için mükemmeldir.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://combistale.com/img/a4.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Pitch-Perfect Pretzels:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Kremalı peynir sosuyla servis edilen sıcak, tuzlu pretzel'ler tüm doğru notaları tutturuyor.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://combistale.com/img/a5.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						High Note Espresso Martini:
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Kahve çekirdekleri ile süslenmiş bu yumuşak kahve ve votka karışımı ile enerjinizi yüksek tutun.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" background="#e2e8f0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Sadece Açılış Oyuncusu
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 20px 0px"
				>
					Bu menü, Melody Haven'da sizi bekleyen lezzetlerin sadece bir önizlemesidir. Menümüzün tamamını incelemek veya özel diyet ihtiyaçlarınız ve tercihleriniz hakkında görüşmek için bize ulaşmaktan çekinmeyin. Gecenizin melodik olduğu kadar lezzetli de olmasını sağlamak için buradayız.
				</Text>
				<Link
					href="/contacts"
					color="--dark"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://combistale.com/img/a6.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});